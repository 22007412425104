import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Icon, Box, Link, Section } from "@quarkly/widgets";
import { BsMap, BsEnvelope, BsClock } from "react-icons/bs";
const defaultProps = {
	"padding": "200px 0px 240px",
	"background": "linear-gradient(180deg,rgba(255, 255, 255, 0.4) 0%, rgba(20, 182, 239, 1) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://flectarn.com/img/5.jpg) 50% 50% /cover repeat scroll padding-box",
	"quarkly-title": "Contacts-7"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--dark",
			"text-align": "center",
			"md-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"margin": "0px 0px 50px 0px",
			"children": "Зв'яжіться з нами"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "36px 34px",
			"margin": "0px 0px 0 0px",
			"sm-grid-template-columns": "1fr",
			"md-grid-template-columns": "1fr"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsMap,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#111111"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--dark",
			"md-margin": "0px 0px 20px 0px",
			"children": "Адреса"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "#111111",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": "36000, м. Полтава, Полтавська обл., бульвар Боровиковського, 4"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsEnvelope,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#111111"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--dark",
			"md-margin": "0px 0px 20px 0px",
			"children": "Телефон"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:050-515-66-41",
			"color": "#111111",
			"text-decoration-line": "initial",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"text-align": "center",
			"margin": "0px 0px 8px 0px",
			"children": "050-515-66-41"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:contact-us@flectarn.com",
			"color": "#111111",
			"text-decoration-line": "initial",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 0 0px",
			"text-align": "center",
			"children": "contact-us@flectarn.com"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsClock,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#111111"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--dark",
			"md-margin": "0px 0px 20px 0px",
			"children": "Працюємо"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#111111",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "10:00 - 20:00"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Icon {...override("icon")} />
				<Text {...override("text1")} />
				<Text {...override("text2")} />
			</Box>
			<Box {...override("box2")}>
				<Icon {...override("icon1")} />
				<Text {...override("text3")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box3")}>
				<Icon {...override("icon2")} />
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;